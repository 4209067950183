import React from 'react'

// loading component for suspense fallback
export default function Loader() {
  return (
    <div>
      <div>loading...</div>
    </div>
  )
}
