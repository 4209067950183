import React from 'react'

export default function Bar(props) {
    const { value } = props
    const styles = {
        width: `${value}%`
    }
    
    return (
        <div className='bar'>
            <div className='inner-bar' style={styles}></div>
        </div>
    )
}