import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {EXPERIENCE} from 'src/constants'
import CardContent from 'src/components/experience/card-content'
import {data} from 'src/data/experience.json'
import Timeline from 'src/components/timeline'
import Section from 'src/components/section'
import * as navigationSelectors from 'src/models/navigation/selectors'

export default function Experience () {
    const {t} = useTranslation()
    const page = useSelector(navigationSelectors.getPage)

  if (page !== EXPERIENCE) {
    return null
  }

  return (
    <Section title={t('experience')}>
      <Timeline
        data={data}
        CardContent={CardContent}
      />
    </Section>
  )
}