import React from 'react'
import {CONTACT_ME} from 'src/constants'
import {useSelector} from 'react-redux'
import Section from 'src/components/section'
import Card from 'src/components/card'
import { useTranslation } from 'react-i18next'
import * as navigationSelectors from 'src/models/navigation/selectors' 

export default function ContactMe () {
  const page = useSelector(navigationSelectors.getPage)
  const {t} = useTranslation()
  const location = 'Gurugram, India'
  const email = 'me@giricgoyal.com'

  if (page !== CONTACT_ME) {
    return null
  }

  return (
    <Section title={t('getInTouch')} >
      <Card className='m-t-30'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d211994.3526672978!2d77.03861613173476!3d28.407845590923795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1600939804773!5m2!1sen!2sin" width="100%" height="300px" frameBorder="0" style={{border:0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        <div className='flex fd-row bg-white'>
          <div className='fd-row flex flex-1 bdr-r-w-1 bdr-r-s-sld bdr-c-grey-base p-10 jc-space-between'>
            <div>{t('location')}:</div>
            <div>{location}</div>
          </div>
          <div className='fd-row flex flex-1 p-10 jc-space-between'>
            <div>{t('email')}:</div>
            <div>{email}</div>
          </div>
        </div>
      </Card>
    </Section>
  )
}