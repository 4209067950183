import React from 'react'
import {useSelector} from 'react-redux'
import { ABOUT } from 'src/constants'
import Section from 'src/components/section'
import Card from 'src/components/card'
import { useTranslation } from 'react-i18next'
import * as navigationSelectors from 'src/models/navigation/selectors'


export default function About() {
    const page = useSelector(navigationSelectors.getPage)
    const {t} = useTranslation()
    if (page !== ABOUT) {
        return null
    }

    return (
        <Section title={t('about')}>
            <Card className='m-t-30 p-15 ws-pre-line'>{t('aboutText')}</Card>
            <Card className='m-t-30 p-15 ws-pre-line'>
                <h1>{t('whyWorkWIthMe')}</h1>
            </Card>
            <Card className='m-t-20 p-15 ws-pre-line'>
                <h3>{t('technicalExpertiseLabel')}</h3>
                <p>{t('technicalExpertiseValue')}</p>
                <h3>{t('userCentricApproachLabel')}</h3>
                <p>{t('userCentricApproachValue')}</p>
                <h3>{t('versatilityLabel')}</h3>
                <p>{t('versatilityValue')}</p>
                <h3>{t('workLifeBalanceLabel')}</h3>
                <p>{t('workLifeBalanceValue')}</p>
            </Card>
        </Section>
    )
}