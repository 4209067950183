import _ from 'lodash';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { SKILLS } from 'src/constants';
import Card from 'src/components/card';
import Section from 'src/components/section'
import SkillRow from './skill-row';
import * as navigationSelectors from 'src/models/navigation/selectors'
import { data as skillsData } from 'src/data/skills.json'

export default function Skills() {
  const { t } = useTranslation()
  const page = useSelector(navigationSelectors.getPage)

  if (page !== SKILLS) {
    return null
  }

  const skillsArea = [
    'frontend',
    'testing',
    'conceptual',
    'backend',
    'analytics',
    'scripting',
    'tools'
  ]

  const skillsByCategory = _.groupBy(skillsData, 'category');
  const skillsGroupJsx = Object.keys(skillsByCategory).map((category) => {
    const categorySkills = skillsByCategory[category];
    const skillsByArea = _.groupBy(categorySkills, 'area');
    const skillsByAreaJsx = skillsArea.map(area => {
      const skills = _.sortBy(skillsByArea[area], 'skill');
      const skillJsx = _.map(skills, skill => (<SkillRow data={skill} />))
      return (
        <Card className='m-t-10 p-15'>
          <div className="bdr-b-c-grey-base bdr-b-s-sld bdr-b-w-1 font-l m-b-10">{t(area)}</div>
          {skillJsx}
        </Card>
      )
    })
    return (
      <div>
        <Card className='m-t-30 p-15 font-xl'>{t(category)}</Card>
        {skillsByAreaJsx}
      </div>
    )
  })

  return (
    <Section title={t('skills')} >
      {/* <Card className='m-t-30 p-5'> */}
        {skillsGroupJsx}
      {/* </Card> */}
    </Section>
  )
}