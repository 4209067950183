import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/components/app';
import * as serviceWorker from 'src/serviceWorker';
import 'src/styles/index.scss'

import { Provider } from 'react-redux'
import store from 'src/redux/store'

import './i18n';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
