import React from 'react'
import {useTranslation} from 'react-i18next'

function CardContent ({data}) {
    const {t} = useTranslation() 
  const {
    areaOfExpertise,
    designation,
    from,
    location,
    logo,
    organization,
    responsibilities,
    technologies,
    to
  } = data

  const technologiesJsx = technologies.map(tech => (
    <span className='bg-primary p-h-5 p-v-2 color-white m-r-5 m-b-5'>{tech}</span>
  ))

  return (
    <div className='card'>
      <div className='card-content'>
        <div className='flex fd-row bdr-b-s-sld bdr-b-c-grey-base bdr-b-w-1 p-b-5'>
          <div className='flex-1 flex fd-col'>
            <div className='card-title'>{organization}</div>
            <div className='font-m '>{location}</div>
          </div>
          <div className='h-50'>
            <img src={`assets/images/${logo}`} className='img-responsive'/>
          </div>
        </div>
        <div className='m-t-10 font-xl '>
          {designation}
        </div>
        <div className='font-l '>
          {areaOfExpertise}
        </div>
        <div className='font-s '>
          {from.month}, {from.year} - {to ? `${to.month}, ${to.year}` : t('present')}
        </div>
        {/* <div className='m-t-30'>
          <div className='text-uppercase  font-m'>
            {t('responsibilities')}:
          </div>
          <ul className='m-t-5 p-l-15'>
            {responsibilities.map(text => (
              <li className='font-m '>{text}</li>
            ))}
          </ul>
        </div>
        <div className='flex flex-wrap-wrap m-t-30'>
          {technologiesJsx}
        </div> */}
      </div>
    </div>
  )
}

export default CardContent
