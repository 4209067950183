import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {PORTFOLIO} from 'src/constants'
import CardContent from 'src/components/portfolio/card-content'
import {data} from 'src/data/portfolio.json'
import MasonryGrid from 'src/components/masonry-grid'
import Section from 'src/components/section'
import * as navigationSelectors from 'src/models/navigation/selectors'

export default function Portfolio () {
  const {t} = useTranslation()
  const page = useSelector(navigationSelectors.getPage)

  if (page !== PORTFOLIO) {
    return null
  }

  return (
    <Section title={t('portfolio')}>
      <MasonryGrid
        data={data}
        CardContent={CardContent}
      />
    </Section>
  )
}
