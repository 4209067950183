import React from 'react'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {EDUCATION} from 'src/constants'
import CardContent from 'src/components/education/card-content'
import {data} from 'src/data/education.json'
import Timeline from 'src/components/timeline'
import Section from 'src/components/section'
import * as navigationSelectors from 'src/models/navigation/selectors'

export default function Education () {
  const page = useSelector(navigationSelectors.getPage)
  const {t} = useTranslation()

  if (page !== EDUCATION) {
    return null
  }

  return (
    <Section title={t('education')}>
      <Timeline
        data={data}
        CardContent={CardContent}
      />
    </Section>
  )
}