import React from 'react'
import SectionHeader from 'src/components/section-header'

export default function Section ({children, title}) {
  return (
    <div className='flex-1 flex fd-col'>
      <div className='fd-col p-h-15 flex-1 flex'>
        <SectionHeader title={title} />
        {children}
      </div>
    </div>
  )
}
