import React from 'react'
import Card from 'src/components/card'

function Item ({CardContent, data}) {
  return (
    <div className='masonry-item m-t-20 w-100p'>
      <Card >
        <CardContent
          data={data}
        />
      </Card>
    </div>
  )
}

export default Item
