import React from 'react'
import {useTranslation} from 'react-i18next'

export default function CardContent ({data}) {
  const {t} = useTranslation()
  const {degree, from, location, major, school, schoolLogo, to} = data

  return (
    <div className='card'>
      <div className='card-content'>
        <div className='flex fd-row  bdr-b-s-sld bdr-b-c-grey-base bdr-b-w-1 p-b-5'>
          <div className='flex-1 flex fd-col'>
            <div className='card-title'>{school}</div>
            <div className='font-m '>{location}</div>
          </div>
          <div className='h-50'>
            <img src={`assets/images/${schoolLogo}`} className='img-responsive'/>
          </div>
        </div>
        <div className='m-t-10 font-xl '>
          {degree}
        </div>
        <div className='font-l '>
          {major}
        </div>
        <div className='font-s '>
          {from.month}, {from.year} - {to ? `${to.month}, ${to.year}` : t('present')}
        </div>
      </div>
    </div>
  )
}
