import React from 'react'
import Item from 'src/components/timeline/item'

function Timeline ({data, CardContent, title}) {
  const cardsJsx = data.map((datum, index) => (
    <Item
      data={datum}
      CardContent={CardContent}
      key={`${title}${index}`}
    />
  ))

  return cardsJsx
}

export default Timeline
