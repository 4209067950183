import _ from 'lodash'
import {ABOUT} from 'src/constants'
import {NAVIGATE} from './action-types'

const initialState = {
  page: ABOUT
}

const navigation = (state = initialState, action) => {
  switch (action.type) {
    case NAVIGATE: {
      return _.defaults({
        page: action.page
      }, state)
    }

    default: {
      return state
    }
  }
}

export default {
  navigation
}
