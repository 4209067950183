import React from 'react'
import Item from 'src/components/masonry-grid/item'
import Masonry from 'react-masonry-component'

export default function MasonryGrid ({data, CardContent, title}) {
  const cardsJsx = data.map((datum, index) => (
    <Item
      data={datum}
      CardContent={CardContent}
      key={`${title}${index}`}
    />
  ))

  return (
    <Masonry>
      {cardsJsx}
    </Masonry>
  )
}

