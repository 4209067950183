import React from 'react'
import classNames from 'classnames'

export default function Card({ children, className }) {
    const classes = classNames(className, {
        'bxs-0-0-10-grey-base-before bg-white pos-rel bdr-rad-5 overflow-hidden': true
    })

    return (
        <div
            className={classes}
        >
            {children}
        </div>
    )
}
