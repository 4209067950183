import React from 'react'
import NavLinks from 'src/components/nav-links'

function Sidebar() {
  return (
    <div
      className='sidebar'
      data-test-id='sidebar'
    >
      <NavLinks />
    </div>
  )
}

export default Sidebar