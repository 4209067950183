import React from 'react'
import ContactMe from 'src/components/contact-me'
import Portfolio from 'src/components/portfolio'
import About from 'src/components/about'
import Education from 'src/components/education'
import Experience from 'src/components/experience'
import Skills from 'src/components/skills'
import Sidebar from 'src/components/sidebar'
import Profile from 'src/components/profile'


// page uses the hook
export default function Page () {
  // const changeLanguage = lng => {
  //   i18n.changeLanguage(lng)
  // };

  return (
    <div className='flex ai-center jc-center h-100vh' data-test-id='page'>
        <div className='page'>
        <Sidebar />
            <div className='page__main-content'>
                <Profile />
                <div className='page__main-content__content-container'>
                    <About />
                    <Experience />
                    <Education />
                    <ContactMe />
                    <Portfolio />
                    <Skills />
                </div>
            </div>
        </div>
    </div>
  )
}

