import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  ABOUT,
  EDUCATION,
  EXPERIENCE,
  CONTACT_ME,
  PORTFOLIO,
  SKILLS,
} from 'src/constants'
import NavLinksItem from './nav-links-item'

export default function NavLinks () {
  const {t} = useTranslation()

  return (
    <div
      className='nav-links'
      data-test-id='nav-links'
    >
      <NavLinksItem iconClassName='fas fa-user' pageId={ABOUT} />
      <NavLinksItem iconClassName='fas fa-chart-line' pageId={EXPERIENCE} />
      <NavLinksItem iconClassName='fas fa-graduation-cap' pageId={EDUCATION} />
      <NavLinksItem iconClassName='fas fa-code' pageId={PORTFOLIO} />
      <NavLinksItem iconClassName='fas fa-crosshairs' pageId={SKILLS} />
      <NavLinksItem iconClassName='fas fa-envelope-open' pageId={CONTACT_ME} />

      {/* <div className='m-t-30'>
        <a
          className='p-v-5 color-blue-grey-lighten-3 text-uppercase p-h-30 flex ai-center'
          href=''
        >
          <i className={`fas fa-file-alt fa-2x w-30`} />
          <span className='p-l-10 m-l-10'>{t('myCv')} <i className="m-l-10 fas fa-external-link-alt"></i></span>
        </a>
      </div>
      <div className=''>
        <a
          className='p-v-5 color-blue-grey-lighten-3 text-uppercase p-h-30 flex ai-center'
          href="http://www.techcouple.info"
          target="_blank"
        >
          <i className={`fab fa-blogger-b fa-2x w-30`} />
          <span className='p-l-10 m-l-10'>{t('myBlog')} <i className="m-l-10 fas fa-external-link-alt"></i></span>
        </a>
      </div> */}
    </div>
  )
}
