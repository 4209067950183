import React from 'react'

export default function SectionHeader ({title}) {
  return (
    <div
      className='p-15 text-uppercase color-primary font-xl bg-white bdr-rad-5'
      key='sectionHeader'
    >
      {title}
    </div>
  )
}