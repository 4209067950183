import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as navigationActions from 'src/models/navigation/actions'
import * as navigationSelectors from 'src/models/navigation/selectors'

export default function NavLinksItem({ iconClassName, pageId }) {
    const { t } = useTranslation()
    const page = useSelector(navigationSelectors.getPage)
    const dispatch = useDispatch()

    const handleLinkClick = (event) => {
        dispatch(navigationActions.navigate(event.currentTarget.dataset.page))
    }

    const getColorClass = (pageIndex) => {
        return page === pageIndex ? 'color-primary' : 'color-grey-base'
    }

    const renderLeftArrow = () => {
        if (page !== pageId) {
            return
        }

        return (
            <div className='left-arrow' />
        )
    }

    const renderRightArrow = () => {
        if (page !== pageId) {
            return
        }

        return (
            <div className='right-arrow' />
        )
    }

    const leftArrowJsx = renderLeftArrow()
    const rightArrowJsx = renderRightArrow()

    return (
        <div className='flex jc-space-between ai-center p-t-5' data-test-id={`nav-link-item-${pageId}`}>
            <a
                className={`p-v-5 ${getColorClass(pageId)} text-uppercase p-h-5 flex fd-col ai-center jc-center ta-center`}
                data-page={pageId}

                onClick={handleLinkClick}
            >
                <i className={`${iconClassName} fa fa-2x`} />
                <span className='font-xs p-t-5'>{t(pageId)}</span>
            </a>
        </div>
    )
}