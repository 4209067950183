import React, { Suspense } from 'react'
import Loader from 'src/components/loader'
import Page from 'src/components/page'

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Page />
        </Suspense>
    )
}
