import React from 'react'
import Bar from 'src/components/bar'
import { useTranslation } from 'react-i18next'

export default function SkillRow(props) {
    const { t } = useTranslation()

    const {
        data: {
            knowledge,
            skill
        }
    } = props
    return (
        <div className="flex ai-center jc-center p-5">
            <div className="w-30p ta-right p-r-10">{t(skill)}</div>
            <div className="flex-1 ">
                <Bar value={knowledge} />
            </div>
        </div>
    )
}