import React from 'react'
import {useTranslation} from 'react-i18next'

export default function CardContent ({data}) {
  const {t} = useTranslation()
  const {
    description,
    from,
    imageUrl,
    organization,
    keywords,
    technologies,
    title,
    to
  } = data

  const technologiesJsx = technologies.map(tech => (
    <span className='bg-primary p-h-5 p-v-2 color-white m-r-5 m-b-5 font-xs'>{tech}</span>
  ))

  const renderCardImage = () => {
    if (!imageUrl) {
      return
    }

    return (
      <img className='img-responsive h-auto w-100p' src={`assets/images/${imageUrl}`}/>
    )
  }

  const imageJsx = renderCardImage()

  return (
    <div className='card'>
      <div className="card-image">
        {imageJsx}
        <div className='card-title'>{title}</div>
      </div>
      <div className='card-content'>
        <div className='font-m'>{description}</div>
        <div className='font-m'>
          {organization}
        </div>
        <div className='font-s'>
          {from.month}, {from.year} - {to ? `${to.month}, ${to.year}` : t('present')}
        </div>
        
        <div className='flex flex-wrap-wrap m-t-15'>
          {technologiesJsx}
        </div>
      </div>
      <div className='card-action'>

      </div>
    </div>
  )
}

