import React from 'react'
import Card from 'src/components/card'

function Item ({CardContent, data}) {
  return (
    <div className='time-line-item'>
      <div className='time-line-dot m-l-25' />
      <div className='top-arrow m-l-20 m-t-2' />
      <Card >
        <CardContent
          data={data}
        />
      </Card>
    </div>
  )
}

export default Item
