import React from 'react'
import Card from 'src/components/card';
import {useTranslation} from 'react-i18next'

export default function Profile () {
  const {t} = useTranslation()
  return (
    <Card className='profile overflow-initial'>
      <div className='p-15 flex-1 ta-center'>
        <img className='bdr-rad-50p bdr-w-1 bdr-s-sld bdr-c-grey-base h-150 w-150 m-b-10' src='assets/images/profile.jpeg' />
        <div className='font-xl text-uppercase'>Giric Goyal</div>
        <div className='font-l text-uppercase'>{t('profileAbout')}</div>
        <div className='m-b-20 '>{t('hobbies')}</div>
        <div>
          <a
            className='m-h-5 '
            href='mailto:me@giricgoyal.com'
            target="_blank"
          >
            <i className="fas fa-2x fa-envelope"></i>
          </a>
          <a
            className='m-h-5 '
            href='https://www.github.com/giricgoyal'
            target='_blank'
          >
            <i className="fab fa-2x fa-github"></i>
          </a>
          {/* <a
            className='m-h-5 '
            href='https://www.facebook.com/giricgoyal'
            target='_blank'
          >
            <i className="fab fa-2x fa-facebook"></i>
          </a> */}
          <a
            className='m-h-5 '
            href='https://www.linkedin.com/in/giricgoyal'
            target='_blank'
          >
            <i className="fab fa-2x fa-linkedin-in"></i>
          </a>
          {/* <a
            className='m-h-5 '
            href='https://www.twitter.com/giricgoyal'
            target='_blank'
          >
            <i className="fab fa-2x fa-twitter"></i>
          </a> */}
        </div>
      </div>
      <div className='flex w-100p'>
        <div className='p-10'>
          {t('freelanceStatus')}
        </div>
        <div className='flex-1 p-10 ta-right'>
          {t('available')}
          <i class="fas fa-circle color-green m-l-10"></i>
        </div>
      </div>
      <div className='flex w-100p'>
        <a className='flex-1 ta-center p-10 bdr-w-0 bdr-s-sld bdr-r-w-1 bdr-t-w-2 bdr-c-grey-base' target="_blank" href='http://www.techcouple.info/'>
          {t('blog')} <i class="fas fa-external-link-alt m-l-2"></i>
        </a>
        <a className='flex-1 ta-center p-10 bdr-s-sld bdr-w-0 bdr-l-w-1 bdr-t-w-2 bdr-c-grey-base' target="_blank" href='https://drive.google.com/file/d/1Sy4MBTOprqDGD8Kf5e83Ov0l_IjY4oY5/view?usp=sharing'>
          {t('resume')} <i class="fas fa-external-link-alt m-l-2"></i>
        </a>
      </div>
    </Card>
  )
}
